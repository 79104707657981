<div class="row w-100 mb-3 logo ">
  <div class="col-lg-5 col-md-5 col-sm-12 float-start header-name">Missed Time Card
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 searchfilter">
     <input autocomplete="off" matInput 
        (keyup)="applyFilter($event.target.value)" type="text"
        class="form-control"
        placeholder="Search Staff ...">
     <mat-icon class="cursor-pointer">search</mat-icon>
  </div>
  <!-- <div class="col-lg-1 col-md-1 col-sm-12 float-end">
     <button mat-raised-button color="primary" class="ms-2" (click)="openShiftPopUp()" title="Add Shift">Add</button>
     </div> -->
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoadingData"></mat-progress-bar>
<div class="w-100 sticky-tbl-th">
  <div class="col-md-12 table-scroll-height"
  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" 
  (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2 position-relative" multiTemplateDataRows>
     <ng-container matColumnDef="workerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staff</th>
        <td  mat-cell *matCellDef="let element" class="text-nowrap"> 
           <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
        </td>
     </ng-container>
     <ng-container matColumnDef="jobType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Type</th>
        <td mat-cell *matCellDef="let element"> {{element.jobType}}</td>
     </ng-container>
     <ng-container matColumnDef="siteName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let element"> {{element.siteName}}</td>
     </ng-container>
     <ng-container matColumnDef="shift">
        <th mat-header-cell *matHeaderCellDef>Shift</th>
        <td mat-cell *matCellDef="let element" class="text-center">
           <span>
           {{element.sartTime}}  <br> {{element.endTime}}
           </span>
           <!-- {{element.sartTime.split(" ")[0]}} - {{element.endTime.split(" ")[0]}} -->
        </td>
     </ng-container>
     <ng-container matColumnDef="clockinTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Clock-In</th>
        <td mat-cell *matCellDef="let element" class="text-center"> {{element.clockinTime ? element.clockinTime : '--'}}</td>
     </ng-container>
     <ng-container matColumnDef="lastKnownTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Known Time</th>
        <td mat-cell *matCellDef="let element" class="text-center"> {{element.lastKnownTime ? element.lastKnownTime : '--'}}</td>
     </ng-container>
     <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
           <ng-template [ngIf]="controlPermission.editPermission === true">
              <button mat-raised-button *ngIf="!element.clockinTime && !element.lastKnownTime" color="primary" (click)="edit_time_card(element)">Edit</button>
              <button mat-raised-button *ngIf="element.clockinTime && element.lastKnownTime" color="primary" (click)="openClockOutPopUp(element)">ClockOut</button>
           </ng-template>
        </td>
     </ng-container>
     <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div class="mt-2">
  <!-- <div class="d-flex align-items-center justify-content-center" *ngIf="isLoadingData">
     <mat-spinner style="width: 25px;"></mat-spinner>
     &nbsp;&nbsp;<span>Getting data, Please wait...</span>
     </div> -->
  <span *ngIf="dataSource?.data.length == 0 && !isLoadingData">No records available</span>
</div>
</div>
<ng-template #update_clock_in_out_time>
  <div class="row">
     <div class="col-md-6 align-self-center">
        <!-- <h6 class="dialog-header pt-2">Clock-Out</h6> -->
     </div>
     <div class="col-md-6">
        <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
        </div>
     </div>
  </div>
  <div class="card-body">
  <app-update-clock-in-out-time
  [missed_clock_in_out_inputs]="missed_clock_in_out_inputs"
  [allow_to_mark_staff_absent_or_notshow]="true"
  (getFormData)="update_missed_clock_in_out_data($event)"
  (absent_Or_NoShow)="staff_absent_Or_NoShow($event)"
  ></app-update-clock-in-out-time>
  </div>
</ng-template>