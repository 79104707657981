<ng-template [ngIf]="loggedIn_userType === 'Customer'" [ngIfElse]="DefaultView_Template">
  <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt">
    <span *ngIf="customerViewLimits?.can_view_staff_profile_pic">
      <img [src]="fileUrl + appPopoverData.userLogo" class="user-image" [class.headAvatar]="lableTxt" alt="User Image"
        onerror="this.src='assets/common_images/profile.jpg';">
    </span>

    <p *ngIf="lableTxt && customerViewLimits?.can_view_staff_name" class="break-words">
      <small>{{lableTxt}}</small><br>
      <span>{{appPopoverData.userName | capitalize}}</span>
    </p>

    <p *ngIf="!lableTxt && customerViewLimits?.can_view_staff_name" class="break-words adjLineHeight">
      <span>{{appPopoverData.userName | capitalize}}</span>
    </p>

  </span>
</ng-template>



<ng-template #DefaultView_Template>
  <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt" appPopover
    [appPopoverData]="appPopoverData" [placement]="'top'">
    <span>
      <img [src]="fileUrl + appPopoverData.userLogo" class="user-image" [class.headAvatar]="lableTxt" alt="User Image"
        onerror="this.src='assets/common_images/profile.jpg';">
    </span>

    <p *ngIf="lableTxt" class="break-words">
      <small>{{lableTxt}}</small><br>
      <span>{{appPopoverData.userName | capitalize}}</span>
    </p>

    <p *ngIf="!lableTxt" class="break-words adjLineHeight">
      <span>{{appPopoverData.userName | capitalize}}</span>
    </p>

  </span>
</ng-template>



<!-- <span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt" appPopover [appPopoverData]="appPopoverData" [placement]="'top'">
  <span>
      <img [src]="fileUrl + appPopoverData.userLogo" class="user-image" [class.headAvatar]="lableTxt" alt="User Image" onerror="this.src='assets/common_images/profile.jpg';">
 </span>
 
  <p *ngIf="lableTxt" class="break-words">
    <small>{{lableTxt}}</small><br>
  <span>{{appPopoverData.userName | capitalize}}</span>
  </p>
  
  <p *ngIf="!lableTxt" class="break-words adjLineHeight">
    <span>{{appPopoverData.userName | capitalize}}</span>
  </p>

</span> -->