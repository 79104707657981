import { Component, OnInit, Inject } from '@angular/core';
import { CheckOutMissedService } from '../check-out-missed-list/check-out-missed-list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/common.service';
import { missed_clock_in_out_inputs } from 'src/app/shared/interface/core.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'clocked-out-pop-up',
  templateUrl: './clocked-out-pop-up.component.html',
  styleUrls: ['./clocked-out-pop-up.component.scss'],
  providers: [CheckOutMissedService]
})
export class ClockedOutPopUp implements OnInit {

  CheckOut_Form: FormGroup;

  checkoutTime: FormControl = new FormControl(null, [Validators.required]);
  submitted: boolean = false;

  clock_out_min_date = moment().toDate();

  missed_clock_in_out_inputs: missed_clock_in_out_inputs;
  private staff_clock_in_time: string = ''; // format should be 'YYYY-MM-DD hh:mm:ss'

  constructor(
     public dialogRef: MatDialogRef <ClockedOutPopUp> ,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private _checkOutMissedSVC: CheckOutMissedService,
     public _commonSVC: CommonService) {}

  ngOnInit(): void {
   //   console.log('ModData', this.data);
     if (this.data) {
        this.initCheckOut_Form(this.data);
     }
  }

  initCheckOut_Form(data: any) {
     this.CheckOut_Form = new FormGroup({
        "appointmentsDurationCode": new FormControl(null),
        "clinicianCheckinDetilsCode": new FormControl(null),
        'checkoutDateTime': new FormControl(null, [Validators.required]),
     });
     if (data)
        this.bindForm(data);
  }


  bindForm(data: any) {
     let mindate = this.data.lastKnownDateFixedFormat ? moment(this.data.lastKnownDateFixedFormat, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD') : moment(this.data.jobDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
     this.clock_out_min_date = moment(mindate).toDate();
     this.staff_clock_in_time = this.data.clockinFixedFormat;
     this.CheckOut_Form.controls['appointmentsDurationCode'].setValue(data.appointmentsDurationCode);
     this.CheckOut_Form.controls['clinicianCheckinDetilsCode'].setValue(data.clinicianCheckinDetilsCode);
     if (data.lastKnownDateFixedFormat || data.lastKnownTime) {
        this.CheckOut_Form.controls['checkoutDateTime'].setValue(data.lastKnownDateFixedFormat ? moment(data.lastKnownDateFixedFormat, 'YYYY-MM-DD').toDate() : moment(data.lastKnownTime, 'MM-DD-YYYY').toDate());
        this.checkoutTime.setValue(this.convertTo12HourFormat(data.lastKnownTime));
     }
  }

  convertTo12HourFormat(dateVal: any) {
     const time12Hour = dateVal.slice(11);

     const [time, period] = time12Hour.split(' ');
     const [hours, minutes] = time.split(':');

     // Convert hours to a number
     let hour = parseInt(hours, 10);

     // If the period is PM and the hour is not 12, add 12 to the hour
     if (period === 'PM' && hour !== 12) {
        hour += 12;
     }

     // If the period is AM and the hour is 12, set the hour to 0
     if (period === 'AM' && hour === 12) {
        hour = 0;
     }

     // Format the hour and minutes as two-digit strings
     const formattedHour = hour.toString().padStart(2, '0');
     const formattedMinutes = minutes.padStart(2, '0');

     // Create the 24-hour time string
     const time24Hour = `${formattedHour}:${formattedMinutes}`;

     // //console.log('time24Hour: ', time24Hour, time12Hour);

     return time24Hour;
  }

  onDateChange(event: any) {
     // let newInputDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');

     //  this.CheckOut_Form.controls["checkoutDateTime"].setValue(newInputDate);
  }

  onTimeChange(event: any) {
     if (event.target.value) {
        this.checkoutTime.setValue(event.target.value);
     }
  }

  saveClockOut() {
     this.submitted = true;

     if (this.CheckOut_Form.valid && this.checkoutTime.valid) {
        if (this.validate_clock_out_time()) {
           let request = {
              "appointmentsDurationCode": this.CheckOut_Form.controls.appointmentsDurationCode.value,
              "clinicianCheckinDetilsCode": this.CheckOut_Form.controls.clinicianCheckinDetilsCode.value,
              "clockoutTime": `${moment(this.CheckOut_Form.controls.checkoutDateTime.value).format('YYYY-MM-DD')} ${this.checkoutTime.value}:00`,
              // "clockoutTime": this.CheckOut_Form.controls.checkoutDateTime.value + ' ' + this.checkoutTime.value + ':00'
              "noteRequest": this._commonSVC.noteRequest ? this._commonSVC.noteRequest : null
           }

           //  console.log('Request', request);
           //  return;

           this._commonSVC.progressLoader = true;
           this._checkOutMissedSVC.saveMissedClockOutByAdmin(request).subscribe((res: any) => {
              this._commonSVC.progressLoader = false;

              if (res.responsecode == 200) {
                 this._commonSVC.resetNotesRequestForm();
                 this.dialogRef.close('Success');
                 this._commonSVC.message = res.message;
                 this._commonSVC.successMessage = true;
              } else {
                 this._commonSVC.message = res.message;
                 this._commonSVC.errorMessage = true;
              }

           });

        }
     }

  }

  validate_clock_out_time(): boolean {
     let isValid: boolean = true;
     let staff_click_in_time = moment(this.staff_clock_in_time, 'YYYY-MM-DD hh:mm:ss')
     let clock_out_time = moment(this.CheckOut_Form.controls.checkoutDateTime.value + ' ' + this.checkoutTime.value + ':00', 'YYYY-MM-DD hh:mm:ss')

     if (clock_out_time.isBefore(staff_click_in_time)) {
        isValid = false;
        this._commonSVC.errorMessage = true;
        this._commonSVC.message = "Clock Out Time should not be before of Clock In Time"
     }

     return isValid;

  }

  resetNote() {
     this._commonSVC.resetNotesRequestForm();
  }

}