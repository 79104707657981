import { ChangeDetectionStrategy, Component, EventEmitter, Input, ChangeDetectorRef, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
 
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
//  import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifiedListComponent } from 'src/app/components/new-job-card/notified-list/notified-list.component';
import { AssignJobComponent } from 'src/app/components/new-job-card/assign-job/assign-job.component';
import { NotifyJobComponent } from 'src/app/components/new-job-card/notify-job/notify-job.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { cust_st_jt_dts, jobCardActions, jobTypeWithShif, missed_clock_in_out_inputs } from 'src/app/shared/interface/core.interface';

// import { jobTypeWithShiftList_ModelRes } from 'src/app/shared/interface/core.interface';
import { NewJobCardService } from '../new-job-card/new-job-card.service';
import { CheckOutMissedService } from '../check-out-missed-list/check-out-missed-list.service';
import { CommonApiService } from 'src/app/shared/services/common-apiService';

@Component({
  selector: 'app-shift-with-job-type',
  templateUrl: './shift-with-job-type.component.html',
  styleUrls: ['./shift-with-job-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShiftWithJobTypeComponent {

  @Input() shiftWithJobTypeInput : jobTypeWithShif ;
  @Input() status: string;
   @Input() allowScheduledStaff_Icon : boolean = true;
  @Input() allowBreaks : boolean = true;

  @Input() allowActions : jobCardActions = {
    allowEdit: true,
    allowDelete: true,
    allowAssignStaff: true,
    allowNotifyStaff: true,
    allowToViewNotifiedStaff: true,
    allowToCopyJob: true,
    allowView: true,
    allowRosterPrint : false,
    allowViewInfo : true,
    allowToAddScheduledStaff : true
  };
 
  
  userType: any;
  
  @Output() updateParent = new EventEmitter();
  @ViewChild('notifypopup') notifypopup: TemplateRef<any>
  @ViewChild("EditJob_PopUp") EditJob_PopUp: TemplateRef<any>;
  @ViewChild("ViewInfo_PopUp") ViewInfo_PopUp: TemplateRef<any>;
  @ViewChild("ViewJobDetails_PopUp") ViewJobDetails_PopUp: TemplateRef<any>;
  @ViewChild("roster_print_popUp") roster_print_popUp: TemplateRef<any>;
   
   
  dialogRef: any;
 
  popupTitle: any;
 
  yearMonthDateFormat = 'YYYY-MM-DD';
 
  EditJobmodalData: any
  copiedJobData: any;
  is_JobCopied: boolean = false;
  jobInfo:any;
 
View_JobmodalData:any ;
@Output() trigger_nextSevenDays = new EventEmitter();
@Output() trigger_previousSevenDays = new EventEmitter();
@Input() allowToCopyJob : boolean  = true ;  // default allow to copy/paste job
protected job_shortData_Modal : cust_st_jt_dts ;

protected jobPaste_date : Date | null;

@ViewChild("jobPaste_calendar") jobPaste_calendar : TemplateRef<any>;

pastDate = moment().toDate();

@ViewChild('update_clock_in_out_time')  update_clock_in_out_time : TemplateRef<any>;
missed_clock_in_out_inputs: missed_clock_in_out_inputs  ;
private pendingJobsCount : number | null = null ;

staffs_for_clock_in_out : any[] = [] ;
@Input() showClockInPIN : boolean = true ;

  constructor(private _translateSVC: TranslateService,
    private _sessionSVC: SessionStorageService,
    private _newCardSVC: NewJobCardService,
    private dialog: MatDialog,
    public _commonSVC: CommonService,
    public dashboarService: DashboardService,
    private ChangeDetectorRef: ChangeDetectorRef,
    private _checkOutMissedSVC : CheckOutMissedService,
    private common_api_service : CommonApiService
  ) {
    this._translateSVC.setDefaultLang(this._sessionSVC.get('language'));
  }

  ngOnInit(): void{
    this.userType = this._sessionSVC.get('user_type');
  } 

  getNotifyList(status: any, appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    this.popupTitle = status;
    const dialogRef = this.dialog.open(NotifiedListComponent, {
      width: '50%',
      disableClose: false,
      panelClass: 'popup-pos',
      data: {status: status, appoinmentCode: appoinmentCode, appoinmentProcedureCode: appoinmentProcedureCode, date: date}
    }); 
  }

  openAssignPopUp(data:any) {
    let popupData = 
    {
      appoinmentCode: data.appointmentsCode,
      appoinmentProcedureCode: data.jobTypeWithShiftList.appointmentsProceduresCode,
      date: data.jobDateFixedFormat,
      required : data.jobTypeWithShiftList.required,
      scheduled : data.jobTypeWithShiftList.scheduled
    }

    this.dialogRef = this.dialog.open(AssignJobComponent, {
      disableClose: true,
      width: '700px',
      panelClass: 'popup-pos',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('CloseAssignPopupReq:', result);
      if (result != undefined && result === 'success') {
        this.updateParent.emit('');
      }
    });    
  }

  openNotifyPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    let popupData = {
      appoinmentCode: appoinmentCode,
      appoinmentProcedureCode: appoinmentProcedureCode,
      date: date
    }

    this.dialogRef = this.dialog.open(NotifyJobComponent, {
      disableClose: true,
      width: '700px',
      panelClass: 'popup-pos',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('CloseNotifyPopupReq:', result);
      if (result != undefined && result === 'success') {
        this.updateParent.emit('');
      }
    }); 
  }

  

  showScheduledStaff(
     customerName:string, 
     date:string,
     siteName:string,
     siteAddress:string,
     appointmentsCode:string,
     selcetedJob_Data:any,
     tempRef: TemplateRef<any>) 
  {
    // this.job_shortData_Modal = {
    //   customerName: customerName,
    //   date: date,
    //   siteName : siteName,
    //   siteAddress : siteAddress ,
    //   appointmentsCode: appointmentsCode ,
    //   jobType_Data : selcetedJob_Data

    // }

    this.job_shortData_Modal = {
      customerName: customerName,
      date: date,
      siteName : siteName,
      siteAddress : siteAddress ,
      appointmentsCode: appointmentsCode ,
      appointmentsProceduresCode : selcetedJob_Data.appointmentsProceduresCode,
      jobTypeName : selcetedJob_Data.jobTypeName,
      jobTypeIcon : selcetedJob_Data.jobTypeIcon,
      rateCard : selcetedJob_Data.showRateCard,  
      jobTypeColor : selcetedJob_Data.jobColor 
    }

    this.dialogRef = this.dialog.open(tempRef, { width: '90%' , maxWidth:'90vw',  panelClass: 'popup-pos' });

    this.dialogRef.afterClosed().subscribe((result) => {
      //this.job_shortData_Modal = "";
  });

  }

  refreshData(event:any) {
    // this.dialogRef.close();
    this.updateParent.emit('');
  }

  CardEditJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string) {
    const initialState = {
      appointmentsCode: appointmentsCode,
      appointmentsProceduresCode: appointmentsProceduresCode,
      jobDate: jobDate,
      isOpenFrom: 'Job_Card_View'      
      // jobDate: selectDate.date, //
    };
    this.EditJobmodalData = initialState;
    this.dialogRef = this.dialog.open(this.EditJob_PopUp, { width: '80%', height:'80%' });
  }

  editJob_RefreshList(event) {
    this.dialogRef.close();
    this.updateParent.emit('');
  }

  cancelCopyPaste()
  {
    this.dialogRef.close();
    this.is_JobCopied = false;
    this.copiedJobData = '';
    this.ChangeDetectorRef.markForCheck();
  }

  CopyJob(customerCode: string, siteAddressCode: string, childData: any) {
    let data = {
      customerCode: customerCode,
      siteAddressCode: siteAddressCode,
      jobData: childData
    }
    this.is_JobCopied = true;
    this.copiedJobData = data;
    // console.log("this.copiedJobData", this.copiedJobData);
    this.dialogRef = this.dialog.open(this.jobPaste_calendar, { width: '25%' });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.cancelCopyPaste();
      });
    
  }

  pasteJobOnSelectedDay(date: string){
     if (this.copiedJobData && date) {
      let array: any = [];
      let temp: any = {
        // shiftMasterCode: this.copiedJobData.jobData.shiftMasterCode,
        shiftMasterCode: null,
        startTime: this.copiedJobData.jobData.startTime,
        endTime: this.copiedJobData.jobData.endTime,
        equipments: this.copiedJobData.jobData.equipments,
        numberOfWorker: this.copiedJobData.jobData.required,
        organizationBaseProcedureCode: this.copiedJobData.jobData.organizationBaseProcedureCode,
        uniform: this.copiedJobData.jobData.uniform,
        ratePerHours : this.copiedJobData.jobData.ratePerHour,
        shiftBreakRequestList: this.copiedJobData.jobData.breakList && this.copiedJobData.jobData.breakList.length > 0 && this.copiedJobData.jobData.breakList.map((ele: any) => {
          return {
            "breakType": ele.breakType,
            "duration": ele.durationMin ? Number(ele.durationMin) :  null,
            "paidStatus": ele.paidStatus === 'Paid' ? true : false
          }
        }) || [],
      };
      
      array.push(temp);
      // let jobDate = moment(day.date).format(this.yearMonthDateFormat);
      //let jobDate = day.yearMonthDateFormat;
      // let orderDate = moment(day.date).format(this.yearMonthDateFormat);
      let final_Data: any =
       {
        customerAddressCode: this.copiedJobData.siteAddressCode,
        // customerAdminCode: this.copiedJobData.jobData.customerAdminCode,
        customerAdminCode: null, // passing null value when copy/paste job
        customerCode: this.copiedJobData.customerCode,
        // endDate: jobDate,
        // startDate: jobDate,
        endDate: date,
        startDate: date,
        jobTypeWithJobData: array,
        // orderDT: orderDate,
        orderDT: '',
        organizationCode: this._sessionSVC.get("orgCode"),
        siteContact: this.copiedJobData.jobData.siteContact,
        siteContactPh: this.copiedJobData.jobData.siteContactPh,
        specification: this.copiedJobData.jobData.specification,
        jobId: "",
        isDateOfMonth: false,
        daysList: [],
        repeatType: "day",
        repeatValue: 1,
      };

      this._commonSVC.progressLoader = true;
      this._newCardSVC.createNewJob(final_Data).subscribe((res: any) => {
        this._commonSVC.progressLoader = false;

        if (res.responsecode == 200) 
        {
          this.updateParent.emit('');
          this.cancelCopyPaste();
          // this.dialogRef.close();
          // // this.is_JobCopied = false;
          // // this.copiedJobData = '';
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
        }
        else 
        {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  }

  CardViewInfo(data:any){
   this.jobInfo = data;
   this.dialogRef = this.dialog.open(this.ViewInfo_PopUp, { width: '60%' , panelClass: 'popup-pos'});
   this.dialogRef.afterClosed().subscribe(result => { this.jobInfo = '' ;  });
  }

  showActionsButtons(date:string){
    return  moment(new Date(date)).format("MM-DD-YYYY") < moment(new Date()).format("MM-DD-YYYY") 
  }

  showPreviousBtn(date:string){
    return  moment(new Date(date)).format("MM-DD-YYYY") <= moment(new Date()).format("MM-DD-YYYY") 
  }

  CardViewJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string){ 
    const initialState = {
      appointmentsCode: appointmentsCode,
      appointmentsProceduresCode: appointmentsProceduresCode,
      jobDate: jobDate,
      isOpenFrom: 'Job_Card_View'
    };
    this.View_JobmodalData = initialState;
    this.dialogRef = this.dialog.open(this.ViewJobDetails_PopUp, { width: '70%', panelClass: 'popup-pos' });
  }

  nextSevenDays(){
    this.trigger_nextSevenDays.emit();
  }

  PreSevenDays(){
    this.trigger_previousSevenDays.emit();
  }


  deleteJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string) 
  {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      disableClose: true,
      data: { msg: 'Are you sure want to delete this shift?' }
    });
  
    dialogRef.afterClosed().subscribe(result => 
      {
      if(result && result.toLowerCase() === 'ok')
      {
        this._commonSVC.progressLoader = true;
        this._newCardSVC.deleteJob(appointmentsCode, jobDate, appointmentsProceduresCode, this._sessionSVC.get("orgCode")).subscribe((res: any) => {
          this._commonSVC.progressLoader = false;
    
          if (res.responsecode == 200) 
          {
            this.updateParent.emit('');
            this._commonSVC.successMessage = true;
            this._commonSVC.message = res.message;
          }
          else 
          {
            this._commonSVC.errorMessage = true;
            this._commonSVC.message = res.message;
          }
        });
      }
    });
 
  }

  JobPasteDateChange(event)
  {
     this.pasteJobOnSelectedDay(moment(event).format(this.yearMonthDateFormat));
  }
 

  updateClockInOutTime(data: any) {

    this.common_api_service.getPendingJobsCount(
      this._sessionSVC.get('orgCode'),
      data.appointmentsCode,
      data.jobTypeWithShiftList.appointmentsProceduresCode,
      data.jobDateFixedFormat
    ).subscribe((res: any) => {
      this.pendingJobsCount = res;
    });
 
    this._commonSVC.progressLoader = true;
    this.common_api_service.getPendingStafForClockInout(
      this._sessionSVC.get('orgCode'),
      data.appointmentsCode,
      data.jobTypeWithShiftList.appointmentsProceduresCode,
      data.jobDateFixedFormat
    ).subscribe((res: any) => {

      this._commonSVC.progressLoader = false;
       
      this.staffs_for_clock_in_out = res?.data || [] ;

      this.missed_clock_in_out_inputs = {

        appointmentsCode: data.appointmentsCode,
        appointmentsProceduresCode: data.jobTypeWithShiftList.appointmentsProceduresCode,

        jobDate: data.date,
        jobFormatedDate: data.jobDateFixedFormat,
        workerCode: null,
        show_prefield_clock_in_out_data: false,

        // shift_start_time: data.jobDateFixedFormat?.startDateTimeFixedFormat,
        shift_start_time: data.jobTypeWithShiftList?.startDateTimeFixedFormat,
         

      }

      this.dialogRef = this.dialog.open(this.update_clock_in_out_time, {
        width: '500px',
        disableClose: true,
        panelClass: 'popup-pos'
      });

      this.dialogRef.afterClosed().subscribe(result => {
        this.reset_clock_in_inputs();
      });

    });


  }


  reset_clock_in_inputs()
  {
    this.missed_clock_in_out_inputs = {
      appointmentsCode : null,
     appointmentsProceduresCode : null,
      jobDate : '' ,    
      jobFormatedDate : '' ,    
      workerCode : '' ,    
      shift_start_time : '',
    
    } ;
  }


  update_missed_clock_in_out_data(data:any)
  {
  
  if(this.pendingJobsCount!=null && this.pendingJobsCount === 0)
    { 
       /* 
       --Case : if count '0'--> staff requirement already fullfilled
       -- Ask confirmation
       */
      this.askAndSave(data) ;
    }
    else
    {
     this.updateMissedClockData(data);
    }
 
  }
 


   /*  
       -- askAndSave()
       -- if staff requirement already fullfilled
       -- Ask confirmation
   */
 askAndSave(data:any)
{
  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '350px',
    data: { msg: 'Requirement is already fullfilled. You want to proceed ahead?' }
  });

  dialogRef.afterClosed().subscribe(result => {

    if (result && result.toLowerCase() === 'ok') 
      {
       this.updateMissedClockData(data);
    }
  
  });
}
  
 /* 
 -- update timecard
 */
updateMissedClockData(data:any)
{
  let req_body = 
  {
   "appointmentsCode": data.missedClockInOutInputs.appointmentsCode,
   "appointmentsProceduresCode": data.missedClockInOutInputs.appointmentsProceduresCode,
   "breakDetailsReqList": data.formData.breakForm || [],
   "clockinTime": data.formData.clock_in + ':00',
   "clockoutTime": data.formData.clock_out + ':00',
   "comment": data.formData.comment,
   "date": data.missedClockInOutInputs.jobFormatedDate ? data.missedClockInOutInputs.jobFormatedDate : moment(data.missedClockInOutInputs.jobDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
   "isApproved": data.isApproved ? true : false,
   "noteRequest": data.noteRequest,
   "organizationCode": this._sessionSVC.get('orgCode'),
   "staffCode": data.staffCode,
 }

 //console.log(req_body)

  this._commonSVC.progressLoader = true;

  this._checkOutMissedSVC.addAdminActionTimeSheet(req_body).subscribe((res: any) => {
   // console.log("res", res);
    this._commonSVC.progressLoader = false;

    if (res.responsecode === 200) {
      this._commonSVC.successMessage = true;
      this._commonSVC.message = res.message;
      //this.closePopup();
      this.dialogRef.close();
      this.updateParent.emit('');
    }
    else {
      this._commonSVC.errorMessage = true;
      this._commonSVC.message = res.message;
    }

  })
}

  closePopup()
  {
    if(this.isDialogOpen())
    {
      this.dialogRef.close();
    }
 }

  isDialogOpen(): boolean {
    return this.dialogRef ? this.dialogRef.getState() === 0 /* MatDialogState.OPEN */ : false;
  }




  
  printDetails(
    customerName:string, 
    date:string,
    siteName:string,
    siteAddress:string,
    appointmentsCode:string,
    selcetedJob_Data:any
  ) 
 {
 
   this.job_shortData_Modal = {
     customerName: customerName,
     date: date,
     siteName : siteName,
     siteAddress : siteAddress ,
     appointmentsCode: appointmentsCode ,
     appointmentsProceduresCode : selcetedJob_Data.appointmentsProceduresCode,
     jobTypeName : selcetedJob_Data.jobTypeName,
     jobTypeIcon : selcetedJob_Data.jobTypeIcon,
     rateCard : selcetedJob_Data.showRateCard,  
     jobTypeColor : selcetedJob_Data.jobColor 
   }

  // console.log("printttttt", this.job_shortData_Modal);

   this.dialogRef = this.dialog.open(this.roster_print_popUp, { width: '85%' ,  panelClass: 'popup-pos' });

   this.dialogRef.afterClosed().subscribe((result) => {
     //this.job_shortData_Modal = "";
 });

 }


 
}
