
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
 import { catchError, Observable, of, shareReplay, switchMap } from 'rxjs';
import { settings_keys } from '../enum/settings.enum';

@Injectable({
  providedIn: 'root'
})

export class CommonApiService {

  constructor(
    public session: SessionStorageService,
    public http: HttpClient,
    public service: ApiRootService, 
  ) { }


  getNotesByEntityCode(organizationCode:string, entityName:string, entityKey:string )
  {
    return this.http.get(`${this.service.getHealthCareUrl() }/getNoteEntityMappingByEntityNameAndKeyAndOrganizationCode/${organizationCode}/${entityName}/${entityKey}`) ; 
  }
 
  saveAndMapNotesEntity(payload:any)
  {
    return this.http.post(`${this.service.getHealthCareUrl() }/saveAndUpdateNoteEntityMapping`,payload) ; 
  }

  getAllCustomerList(orgCode:string,) {
    // return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + orgCode).pipe(shareReplay(1));
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + orgCode);
  }

  
  getSettingNameDetailsBySettingName(settingName: string, orgCode: string) {
    return this.http.get(
      this.service.getHealthCareUrl() +
      "/getSettingNameByName/" +
      settingName +
      "/" +
      orgCode
    );
  }


  get_calendar_setting_by_setting_name(orgCode:string): Observable<any> 
  {
    return this.getSettingNameDetailsBySettingName(
      settings_keys.CALENDAR_VIEW,
      orgCode
    ).pipe(
      switchMap((outer_res: any) => {
        if (outer_res.responsecode === 200) {
          if (outer_res.settingValue === "Start of week") {
            return this.getSettingNameDetailsBySettingName(
              settings_keys.START_OF_WEEKS,
              orgCode
            ).pipe(
              switchMap((inner_res: any) => {
                if (inner_res.responsecode === 200) {
                  return of({
                    "setting_calendar_view": outer_res.settingValue,
                    "setting_start_of_week": inner_res.settingValue
                  });
                } else {
                  return of({
                    "setting_calendar_view": outer_res.settingValue,
                    "setting_start_of_week": ""
                  });
                }
              }),
              catchError(() => {
                return of({
                  "setting_calendar_view": outer_res.settingValue,
                  "setting_start_of_week": ""
                });
              })
            );
          } else {
            return of({
              "setting_calendar_view": outer_res.settingValue,
              "setting_start_of_week": ""
            });
          }
        } else {
          return of({
            "setting_calendar_view": "",
            "setting_start_of_week": ""
          });
        }
      }),
      catchError(() => {
        return of({
          "setting_calendar_view": "",
          "start_of_week": ""
        });
      })
    );
  }


  get_setting_value_by_setting_name(settingName:string, orgCode:string) {
    return this.http
      .get(
        this.service.getHealthCareUrl() +
        "/getSettingNameByName/" +
        settingName +
        "/" +
        orgCode
      );
   }


   get_all_staff_by_org_code(organizationCode:string)
   {
     return this.http.get(this.service.getHealthCareUrl() + `/getAllStaffMember/${organizationCode}`);
   }

   getPendingJobsCount(organizationCode:string, appointmentsCode:string, appointmentsProceduresCode:string, date:string)
   {
     return this.http.get(this.service.getHealthCareUrl() + `/getPendingJobCount/${organizationCode}/${appointmentsCode}/${appointmentsProceduresCode}/${date}`);
 
   }


   getPendingStafForClockInout(organizationCode:string, appointmentsCode:string, appointmentsProceduresCode:string, date:string)
   {
     return this.http.get(this.service.getHealthCareUrl() + `/getAllStaffMemberExceptThisJob/${organizationCode}/${appointmentsCode}/${appointmentsProceduresCode}/${date}`);
 
   }
 
   getExpiredDocCountsByOrgCode(organizationCode:string)
   {
     return this.http.get(this.service.getHealthCareUrl() + `/getCountOfSkillAndCertificateIsExpiredByOrgCode/${organizationCode}`);
 
   }
    
}
 