import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewJobCardService } from '../new-job-card/new-job-card.service';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';
import { Observable, interval, map } from 'rxjs';
import { cust_st_jt_dts } from 'src/app/shared/interface/core.interface';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss']
})
export class CommonCardComponent implements OnInit {

  @Input() job_shortData_Modal : cust_st_jt_dts ;
  @Output() emitter_handleEvent = new EventEmitter();
  
  // protected s_staffList : any = [];
  protected s_staffList : any = {};
  protected page : number = 0 ;
  protected limit : number = 10 ;

constructor( 
  private _newCardSVC: NewJobCardService,
   private _sessionSVC: SessionStorageService,
   public _commonSVC: CommonService){}

ngOnInit(): void 
{
  this.getAllScheduledStaff();
}

  getAllScheduledStaff(isScroll?)
  {

    if(!isScroll){
      this._commonSVC.progressLoader = true;
    } 

      this._newCardSVC.getAllScheduledStaff(
      this._sessionSVC.get('orgCode'), 
      this.job_shortData_Modal.appointmentsCode, 
      this.job_shortData_Modal.appointmentsProceduresCode, 
      this.job_shortData_Modal.date ,
      this.page ,
      this.limit
      ).subscribe((res:any) => {
        this._commonSVC.progressLoader = false;

        if(res.responsecode == 200)
          {

            // res.data.forEach(ele => {
            //   if(ele.inBreak)
            //     {
            //       ele.breakStart_timer$ = this.createTimerObservable(ele.breakStartDateTimeFixedFormat , ele.timeZoneName);
               
            //     }
            // })

            if(isScroll)
              {
                this.s_staffList.jobDurationDetailsList = [...this.s_staffList.jobDurationDetailsList, ... res.data.jobDurationDetailsList]
              }
              else
              {

                this.s_staffList = res.data;
              }
             
           
          }
      })
  }

  onScrollStaff()
  {
    this.page +=1;
    this.getAllScheduledStaff('scroll');
  }


  // private createTimerObservable(startTime: string , timezone:string):Observable<any> {
  //   return interval(1000).pipe(
  //     map(() => {
  //       // let timezone_formate = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')
  //       // const currentTime = new Date(timezone_formate).getTime();
  //       // const elapsedTime = currentTime - new Date(startTime).getTime();
  //       // let diff = moment(moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')).diff(startTime)
  //       //let diff = moment(startTime).diff(moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss'))
  //       return this.diff(startTime,timezone);
     
  //     })
  //   );
  // }
  
  // private diff(givenDateString: string , timezone:string)
  // {
  //   const givenDate = moment.tz(givenDateString, timezone);
  
  //  const currentDate = moment.tz(timezone);
  
  // //  const difference = givenDate.diff(currentDate);   // for showing in decresed order
  
  //  const difference = currentDate.diff(givenDate);
  
  //  const duration = moment.duration(difference);
  // const daysDifference = Math.floor(duration.asDays());
  // const hoursDifference = duration.hours();
  // const minutesDifference = duration.minutes();
  // const secondsDifference = duration.seconds();
  
  // // console.log('Difference in days:', daysDifference);
  // // console.log('Difference in hours:', hoursDifference);
  // // console.log('Difference in minutes:', minutesDifference);
  // // console.log('Difference in seconds:', secondsDifference);
  
  // return `${hoursDifference}h ${minutesDifference}m ${secondsDifference}s`;
  
  // } 

  refreshData(event:any){
    console.log('RefreshData_Rec', event);
      this.page = 0 ;
     // this.s_staffList = {} ;
      this.getAllScheduledStaff();
      this.emitter_handleEvent.emit(event);
  }

}
