import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { LayoutRoutingModule } from "./common/common-routing.module";
import { AuthService } from "./shared/auth.service";
import { AuthGuardService } from "./shared/auth-guard.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { CommonModule } from "./common/common.module";
// import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { myRxStompConfig } from "./my-rx-stomp.config";
// import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CountryCodeModel } from "./common/country-code/country-code.model";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import {
  LocationStrategy,
  Location,
  PathLocationStrategy,
  HashLocationStrategy,
} from "@angular/common";
import { RxStompService } from "./rx-stomp.service";
import { rxStompServiceFactory } from "./rx-stomp-service-factory";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
//import { NewJobComponent } from './dashboards/providers-dashboard/appointment/new-job/new-job.component';
import { DragDropModule } from "@angular/cdk/drag-drop";

//import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { ErrorLoggingService } from "./shared/services/error-logging.service";
import { GlobalErrorHandler } from "./shared/global-error-handler";
// import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { httpInterceptorProviders } from "./core/interceptors";
import { CommonApiService } from "./shared/services/common-apiService";
 
// import { ValidateRouteService } from "./shared/services/validate-route.service";
 
//import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

// const customNotifierOptions: NotifierOptions = {
//   position: {
//     horizontal: {
//       position: 'left',
//       distance: 12
//     },
//     vertical: {
//       position: 'bottom',
//       distance: 12,
//       gap: 10
//     }
//   },
//   theme: 'material',
//   behaviour: {
//     autoHide: 5000,
//     onClick: 'hide',
//     onMouseover: 'pauseAutoHide',
//     showDismissButton: true,
//     stacking: 4
//   },
//   animations: {
//     enabled: true,
//     show: {
//       preset: 'slide',
//       speed: 300,
//       easing: 'ease'
//     },
//     hide: {
//       preset: 'fade',
//       speed: 300,
//       easing: 'ease',
//       offset: 50
//     },
//     shift: {
//       speed: 300,
//       easing: 'ease'
//     },
//     overlap: 150
//   }
// };

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutRoutingModule,
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    // NotifierModule.withConfig(customNotifierOptions),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    //ModalModule.forRoot(), // Add this line
    BrowserAnimationsModule,
    DragDropModule,
    /*  LoggerModule.forRoot({
      serverLoggingUrl: 'http://localhost:68552/', // Replace with YOUR API
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false
    }) */
    // LoggerModule.forRoot({
    //   serverLoggingUrl: 'https://api-dev.gigflex.com:8762/superadminservice/saveProjectException', // Set your server-side logging URL
    //   level: NgxLoggerLevel.DEBUG,
    //   serverLogLevel: NgxLoggerLevel.ERROR,
    // }),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthService,
    AuthGuardService,
    CountryCodeModel,
    BsModalRef,
    httpInterceptorProviders,
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // {
    //   provide: InjectableRxStompConfig,
    //   useValue: myRxStompConfig
    // },
    // {
    //   provide: RxStompService,
    //   useFactory: rxStompServiceFactory,
    //   deps: [InjectableRxStompConfig]
    // },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
   { provide: LocationStrategy, useClass: HashLocationStrategy },
    ErrorLoggingService,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CommonApiService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
