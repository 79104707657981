<!-- <p>change-time-card works!</p> -->
<div class="row">
  <div class="col-md-12 col-sm-12">
    <div class="row">
      <div class="col-md-12 align-self-center">
        <!-- Update Time for the date {{ missed_clock_in_out_inputs.jobDate ? missed_clock_in_out_inputs.jobDate : missed_clock_in_out_inputs.jobFormatedDate}} -->
        <h6 class="skill-test pt-2">Update Timecard information for shift
          <span class="headerNoteTitleBtn">
            <span class="ps-4 cursor-pointer" (click)="addUpdateNotes()">
              <span class="notebtnContainer"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" />
                <span class="ps-1 noteBtnTxtInactive">Note</span>
              </span>
            </span>
          </span>
        </h6>
      </div>
      <div class="col-md-2">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" mat-dialog-close (click)="resetNotes()"></button>
        </div>
      </div>
    </div>

    <div class="content-height">
    <ng-template [ngIf]="allow_to_mark_staff_absent_or_notshow">
    <div class="row">
      <div class="col-6">
        <mat-checkbox class="example-margin" [(ngModel)]="mark_no_show" [checked]="mark_no_show" (change)="onCheck_mark_no_show($event, 'No Show')">Mark as no-show</mat-checkbox>
      </div>
      <div class="col-6">
        <mat-checkbox class="example-margin" [(ngModel)]="mark_absent"  [checked]="mark_absent" (change)="onCheck_mark_absent($event, 'Absent')">Mark as absent</mat-checkbox>
      </div>
    </div>
  </ng-template>

    <form [formGroup]="form_update_clockInOut_time">
 
      <div class="row mt-3">
        <div class="col-6">
          <staff-list-select [selected_staff_code]="selectedStaffCode" (selectionChanged)="getSelectedStaff($event)"
            [disabled]="is_disabled" [staffList]="eligibleStaff">
          </staff-list-select>
          <div class="help-block err staff_err"
            *ngIf="form_update_clockInOut_time.get('selectedStaffCode').errors?.required && (is_form_submitted || form_update_clockInOut_time.get('selectedStaffCode').touched)">
            Please select a staff it's required
          </div>
        </div>
      </div>

      <ng-template [ngIf]="missed_clock_in_out_inputs.show_prefield_clock_in_out_data">
        <div class="row">
          <div class="col">
            <small>Existing Clock-in time</small><br>
            <p>{{missed_clock_in_out_inputs.prefield_clock_in ? missed_clock_in_out_inputs.prefield_clock_in : '--'}}
            </p>
          </div>

          <div class="col">
            <small>Existing Clock-out time</small><br>
            <p>{{missed_clock_in_out_inputs.prefield_clock_out ? missed_clock_in_out_inputs.prefield_clock_out : '--' }}
            </p>
          </div>

        </div>
      </ng-template>

      <div class="row g-3">
        <!-- [ngClass]="{'is-invalid': checkinTime.invalid && (!submitted || checkinTime.touched)}" -->
        <div class="form-group col position-relative">
          <label class="positionAbsLabel">New Clock-in time</label>
          <input type="time" class="form-control timeInput" value="10:05 AM" formControlName="clock_in"
            [ngClass]="{'is-invalid':  form_update_clockInOut_time.get('clock_in').errors?.required && (is_form_submitted || form_update_clockInOut_time.get('clock_in').touched) }"
            (change)="onTimeChange($event)" placeholder="Choose a Time" />


          <div class="help-block err"
            *ngIf="form_update_clockInOut_time.get('clock_in').errors?.required && (is_form_submitted || form_update_clockInOut_time.get('clock_in').touched)">
            Clock-in time is required
          </div>

          <div class="help-block err"
            *ngIf="form_update_clockInOut_time.get('clock_in').errors?.before_shift_start_time && (is_form_submitted || form_update_clockInOut_time.get('clock_in').touched)">
            Clock-in time should not be before shift start time
          </div>

        </div>

        <div class="form-group col position-relative">
          <label class="positionAbsLabel">New Clock-out time

          </label>
          <input type="time" class="form-control timeInput" value="10:05 AM" formControlName="clock_out"
            [ngClass]="{'is-invalid':  form_update_clockInOut_time.get('clock_out').errors?.required && (is_form_submitted || form_update_clockInOut_time.get('clock_out').touched) }"
            (change)="onTimeChange($event)" placeholder="Choose a Time" />

          <div class="help-block err"
            *ngIf="form_update_clockInOut_time.get('clock_out').errors?.required && (is_form_submitted || form_update_clockInOut_time.get('clock_out').touched)">
            Clock-out time is required
          </div>

        </div>

        <div class="col-1" *ngIf="is_late_night">
          <span title="Late Night">
            <img class="half-moon" src="../../../assets/icons/svg/half-moon.svg" alt="">
          </span>
        </div>


      </div>


      <div class="row">
        <div class="col-12">
          <a *ngIf="!add_break" mat-stroked-button color="primary" [disabled]="disable_addBreak" (click)="toggle_break()">Add Break
            &nbsp;<mat-icon>keyboard_arrow_right</mat-icon>
          </a>
          <ng-template [ngIf]="add_break">
            <!-- <div class="break-form-height"> -->
              <!-- <div> -->
              <app-break-form [formData]="form_update_clockInOut_time.controls.breakForm.value"
                (getFormData)="captureFormData($event)" (noBreaks)="handleNoBreak($event)"></app-break-form>
            <!-- </div> -->
          </ng-template>
        </div>
      </div>



      <div class="row mt-4">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Comment</mat-label>
          <textarea autoClose matInput formControlName="comment" type="text" placeholder="Leave a comment"></textarea>
        </mat-form-field>
        <!-- 
            <span class="help-block err"
            *ngIf="inputForm.controls.comment.errors?.required && (!submitted || inputForm.controls.comment.touched)">Comment is require
           </span> -->
      </div>


    </form>

    </div>

    <div class="row mt-4">

      <div class="col-3 text-start" >
        <button
        *ngIf="allowTodeleteTimeCard"
         type="submit" 
         mat-raised-button color="warn"  
        (click)="deleteTimeCard()"
        >Delete
      </button>
      </div>

      <div class="col-9 text-end">
        <button mat-button mat-dialog-close class="cancel-btn-primary me-3" (click)="resetNotes()">Cancel</button>
        <!-- <button type="submit" mat-raised-button color="primary" class="me-3" (click)="save_form()">Save</button> -->

        <ng-template [ngIf]="mark_no_show || mark_absent" [ngIfElse]="regular_buttons">
          <button type="submit" mat-raised-button color="primary" class="me-3"
        (click)="changeStaffStatus()">{{mark_absent ? 'Mark as absent' : 'Mark as no-show'}}</button>
        </ng-template>

        <ng-template #regular_buttons>

          <button *ngIf="!missed_clock_in_out_inputs?.is_timecard_approved " type="submit" mat-raised-button color="primary" class="me-3"
          (click)="check_validation_and_save()">Save</button>

        <button *ngIf="user_type!=='Customer'" type="submit" mat-raised-button color="primary" class="me-0"
          (click)="save_and_approve_form()">
         {{ missed_clock_in_out_inputs.is_timecard_approved ? 'Update' : 'Save & Approve'}}
        </button>

          </ng-template>
     </div>
    </div>
  </div>
</div>